import React, { useEffect } from 'react';
import { Container, Grid, Card, Link, Typography } from '@mui/material';
import Banner from '../component/Banner';
import ResponsiveAppBar from '../component/ResponsiveAppBar';
import './About.css';

function About() {
  useEffect(() => {
    document.title = 'About | Resident Evil Timeline App';
  });
  return (
    <>
      <ResponsiveAppBar />
      <Banner />
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h1">About</Typography>
            <p>Welcome to the Resident Evil Timeline App. Have you ever asked or seen the following;</p>
            <Card sx={{ my: 2, px: 2 }} variant="outlined">
              <blockquote>
                <p>"Hi I'm new to Resident Evil, what is the timeline of the series?"</p>
              </blockquote>
            </Card>
            <p>This is possibly the most common question on forums and unfortunately has no straightforward answer. Since the franchise contains so much media, thorough lists often contain much more data than a single person is likely looking for, and paradoxically any restricted list will fail to cover all possible needs. The <strong>Resident Evil Timeline App</strong> aims to be an all-encompassing tool that resolves the issue static timelines have. This app is customizable and will satisfy whichever criteria one may be searching for. It has the functionality to;</p>
            <ul>
              <li>select which media to include i.e. games, movies, both, everything etc.</li>
              <li>select which canons are included i.e. main, anderson, no-plot, etc.</li>
              <li>sort by release date or by in-universe date</li>
              <li>provide the option to show entries like Resident Evil 3: Nemesis as single or multiple entries to accurately represent the multiple instances it takes place in time</li>
              <li>toggle key information to show; lore info, availability, release date, a picture, and a general description</li>
              <li>provide links to Resident Evil Wiki and where to immediately access the product</li>
            </ul>
            <p>Create the exact timeline you've been looking for!</p>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Data key notes</Typography>
            <ul>
              <li>Video games are split between "Major" and "Minor" based loosely on if they follow the  core gameplay of the current numbered title, have been ported past their launch generation, and relative size, budget, &amp; marketing. This is not a denomination of "main" and "side" games.</li>
              <li>The "Canon" a product belongs to is usually named after the writer or group most associated with having produced that continuity. For ease of use, some canons are grouped together. The "Main branches" of canons are products made with the main canon in mind but aren't part of the main canon themselves. "Pocket" canons are wholly unique to themselves, not based on the main canon and have no other media in their canon to make up a continuity. Otherwise canons are not officially defined.</li>
              <li>"Simple" sorting will display each product as one entry and dated in-universe where a majority of its contents take place. "Sectioned" will break up products into separate entries if it has sections set during different time periods.</li>
              <li>The earliest release date is used. "WW" stands for "World Wide".</li>
              <li>Products only available in Japan retain their Japanese "BIOHAZARD" name as there are no official "Resident Evil" versions of them.</li>
              <li>The retelling scenarios in the Chronicles games are not listed, only the original scenarios.</li>
            </ul>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Version history</Typography>
            <Typography sx={{ mt: 2 }} variant="h6" component="h3">1.0 - September 4, 2023</Typography>
            <ul>
              <li>Website launch.</li>
            </ul>
            <Typography sx={{ mt: 2 }} variant="h6" component="h3">1.1 - October 15, 2023</Typography>
            <ul>
              <li>The Resident Evil Podcast's "THE RESIDENT EVIL/BIOHAZARD MYTHOLOGY" timeline link added to <Link href="/further-reading">Further Reader</Link> page.</li>
              <li>"Biohazard Village (pachislot)" added to database.</li>
              <li>"A Resident Evil Story" added to database. Was previously lost media has been archived, made accessible, and shared by @walruslaw, @YevgENITCH and @PonyBoyPGH.</li>
            </ul>
            <Typography sx={{ mt: 2 }} variant="h6" component="h3">1.2 - December 11, 2023</Typography>
            <ul>
              <li>Accessibility link added for "Biohazard The Stories". Was previously lost media; has been archived and made accessible by RockmanCosmo, MemoryHunter, usernameak & LNRC.</li>
              <li>Accessibility link added for "biohazard 0 (manhua)". Translation was done by Lunar Indie Studios and hosted by Project Umbrella. Please look forward to their translations of the other manhaus in the future!</li>
              <li>"Resident Evil Outbreak (Flash game)" added to database. Was previously unarchived, recovered by ParallelTraveler. Investigation was started at the request of biohazardoctor.</li>
            </ul>

            <Typography sx={{ mt: 2 }} variant="h6" component="h3">1.3 - June 19, 2024</Typography>
            <ul>
              <li>Entries added for previously missing Pachislot games.</li>
              <li>Entry added for "Biohazard The Mercenaries 3D (comic)".</li>
              <li>Accessibility link added for "Resident Evil The Mercenaries VS". Was nearly lost media until it had been saved and archived by XthemastaX.</li>
              <li>Accessibility links added for "Biohazard (novel)" and "Black Winged Resident". Translations by Wyntr.</li>
              <li>Data fixes: Lore date for Jessica's Report, Release date for Pachislot Vendetta, some typos.</li>
              <li>Notice: Biohazard 2 (manhua) has recently been fully translated by lunarindie and uses the same link that has been listed before.</li>
            </ul>

            <Typography sx={{ mt: 2 }} variant="h6" component="h3">1.4 - March 2025</Typography>
            <ul>
              <li>Updated availability for Resident Evil 2, 4, 7, & Village to note they're on Mac & iOS.</li>
              <li>Updated OG Resident Evil 1, 2, & 3 to note they're on GOG.</li>
              <li>Link to ParallelTraveler's write up for Operation Re:Quest added to Extra tab</li>
              <li>Accessibilities links added for Chainsaw Maniac</li>
            </ul>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Credits</Typography>
            <p>Our team consists of dedicated fandom benefactors who have devoted countless hours to creating online resources for accessing and understanding the Resident Evil franchise. We hail from different communities and have essentially joined forces for this venture. We hope the greater fandom appreciates this tool, reach out to let us know if you found it useful! Interested in helping to further develop and add features? Contact us and ask!</p>
            <Card sx={{ my: 2, px: 2 }}>
              <Typography sx={{ mt: 2 }} variant="h6" component="h3">ParallelTraveler (Project director)</Typography>
              <p>Resident Evil wiki admin. Has been archiving information and creating resources for over a decade. Also a bartender that makes Resident Evil themed drinks.</p>
              <p>Twitter: <Link href="https://twitter.com/bioranger_PT" target="_blank">@bioranger_PT</Link></p>
              <p>Personal blog: <Link href="https://bioredqueeneffect.wordpress.com/" target="_blank">bioredqueeneffect.blogspot.com</Link></p>
            </Card>
            <Card sx={{ my: 2, px: 2 }}>
              <Typography sx={{ mt: 2 }} variant="h6" component="h3">EvilResource (Main developer)</Typography>
              <p>Owner of evilresource.com. Has been documenting data from the Resident Evil games since 1996. Web development is both his day job and his hobby.</p>
              <p>Twitter: <Link href="https://twitter.com/EvilResource" target="_blank">@EvilResource</Link></p>
              <p>Website: <Link href="https://www.evilresource.com/" target="_blank">www.evilresource.com</Link></p>
            </Card>
            <Card sx={{ my: 2, px: 2 }}>
              <Typography sx={{ mt: 2 }} variant="h6" component="h3">MassDistraction (General assistance)</Typography>
              <p>Resident Evil Reddit (r/residentevil) contributor that has made other timeline documents. Collects and archives Resident Evil media.</p>
              <p>Twitter:: <Link href="https://twitter.com/massaharhautus" target="_blank">@massaharhautus</Link></p>
              <p>Linktree: <Link href="https://linktr.ee/massdistraction" target="_blank">linktr.ee/massdistraction</Link></p>
            </Card>
            <Card sx={{ my: 2, px: 2 }}>
              <Typography sx={{ mt: 2 }} variant="h6" component="h3">Dnkakusei (ARG assistance)</Typography>
              <p>Film fan and player of all kinds of games. Writes for imfdb.org in his spare time.</p>
              <p>Carrd: <Link href="https://sawazaki.carrd.co/" target="_blank">sawazaki.carrd.co</Link></p>
            </Card>
            <Card sx={{ my: 2, px: 2 }}>
              <Typography sx={{ mt: 2 }} variant="h6" component="h3">Skept1c (ARG assistance, graphic designer)</Typography>
              <p>Resident Evil challenge runner and ARG fan. Also plays Dead by Daylight and makes Youtube videos for fun.</p>
              <p>YouTube: <Link href="https://www.youtube.com/@skept1c" target="_blank">www.youtube.com/@skept1c</Link></p>
            </Card>
            <Card sx={{ my: 2, px: 2 }}>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="h3">Cakestina (Graphic designer)</Typography>
            </Card>

            <Card sx={{ my: 2, px: 2 }} variant="outlined">
              <Typography sx={{ mt: 2 }} variant="h6" component="h3">Special thanks</Typography>
              <p>Resident Evil Wiki, Project Umbrella, Roronoa Wesker, Crimson Head Elder, Resident Evil Live, Blue Horror Gaming, Comfort Food Comics, @K_Syuuka, @_Gosetsu, u/Aron_Blue, Angelico, Silia, 𝗚𝗹𝗶𝘁𝗰𝗵𝗲𝗱𝗦𝗾𝘂𝗶𝗿𝘁𝗹𝗲𒉭, JTeghius Kittius, reenbeanVA, Resident_bio, ResiEvilCentral, @walruslaw, @YevgENITCH, @PonyBoyPGH, RockmanCosmo, MemoryHunter, usernameak, LNRC, Lunar Indie Studios, Wyntr, XthemastaX - For sharing information, hosting content this app links to, and other assistance.</p>
              <p>Inconceivable - Helping with initial conceptualization, hope you are doing well.</p>
            </Card>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Disclaimer</Typography>
            <p>This non-commercial product is not affiliated with or endorsed by Resident Evil or its creators. The content provided is for entertainment and educational purposes only.</p>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default About;
